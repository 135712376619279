import React, { useContext, useState } from "react";
import { Card, Typography, Tag } from "shared";
import { createUseStyles } from "react-jss";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../pages/_app";
import { Tooltip } from "antd";

interface Props {
  image: string;
  description?: string;
  dimension: string;
  title?: string;
  tag: number;
  id: number;
  carpet_id: string;
  fullRated?: boolean;
}
const useStyles = createUseStyles({
  wrapper: {
    width: 225,
    height: "fit-content",
    "@media(max-width: 768px)": {
      width: 132,
    },
  },
  dimension: {
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
    display: "flex",
    justifyContent: "center",
    fontSize: 20,
    lineHeight: "30px",
  },
  title: {
    position: "relative",
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
      margin: "12px 0px 10px 0px",
    },
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "bold",
    margin: "11px 4.5px 15px 4.5px",
  },
  description: {
    "@media(max-width: 768px)": {
      fontSize: 12,
      height: "58px",
      lineHeight: "18px",
      width: 132,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 0,
    },
    height: "63px",
    fontSize: 14,
    lineHeight: "21px",
    color: "#82999B",
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 12.5,
    overflow: "hidden",
    overflowWrap: "normal",
    textOverflow: "ellipsis",
    width: 200,
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },

  iconStyle: {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    height: 50,
  },
  tag: {
    width: 70,
    fontSize: 10,
    lineHeight: "12px",
    marginRight: 0,
  },
  tagWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  recomend: {
    position: "absolute",
    top: 0,
    zIndex: "1000",
    left: 0,
    "@media (max-width: 768px)": {
      width: 100,
      top: -8,
      left: -10,
      height: 90,
    },
  },
});
const CarpetCard = (props: Props) => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const id = props.id;

  const binary = Number(props.tag).toString(2).padStart(6, "0");
  const lockCond = binary.charAt(0) == "1" ? !user : false;
  const handleClick = () => {
    setLoading(true);
    router.push(
      {
        pathname: "/carpet-details/[id]",
        query: { carpet_id: id },
      },
      `/carpet-details/${props.carpet_id}`
    );
  };

  let tags: any;

  if (binary.charAt(4) == "1") {
    tags = (
      <div className={classes.tagWrapper}>
        <Tag className={classes.tag} type="new">
          {t("New")}
        </Tag>
      </div>
    );
  }

  if (binary.charAt(3) == "1") {
    tags = (
      <div className={classes.tagWrapper}>
        <Tag className={classes.tag} type="booked">
          {t("Reserved")}
        </Tag>
      </div>
    );
  }

  if (binary.charAt(2) == "1") {
    tags = (
      <div className={classes.tagWrapper}>
        <Tag className={classes.tag} type="sold">
          {t("Sold")}
        </Tag>
      </div>
    );
  }

  return (
    <>
      <div className={classes.wrapper}>
        {props.fullRated && (
          <img
            className={classes.recomend}
            src="/assets/recoo.png"
            width={120}
            height={100}
            alt="high qulity rugs and carpets in japan"
          />
        )}
        <Card
          image={props.image.replace("original", "thumbnail")}
          onClick={handleClick}
          loading={loading}
        />

        <Typography className={classes.title}>
          {props.title}
          {lockCond && (
            <Tooltip
              placement="topLeft"
              title={"絨毯の画像を表示するために、ログインする必要あります。"}
            >
              <img src="/assets/lock.svg" className={classes.iconStyle} />
            </Tooltip>
          )}
        </Typography>
        <Typography className={classes.dimension}>{props.dimension}</Typography>
        <Typography className={classes.description}>
          {props.description}
        </Typography>
        {tags}
      </div>
    </>
  );
};
export { CarpetCard };
