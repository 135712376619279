import React from "react";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

interface SliderProps {
  maxValue?: number;
  minValue?: number;
  step?: number;
  onChange?: any;
  title?: string;
  marks?: any;
  value?: any;
}
const useStyles = createUseStyles({
  wrapper: {
    "& .ant-slider-track": {
      backgroundColor: "#1B2E32 !important",
      "& div": {
        backgroundColor: "#1B2E32",
      },
    },
  },
  mainTitle: {
    marginBottom: 60,
    fontFamily: ".Aqua Kana",
    fontSize: 18,
    lineHeight: "27px",
    "@media(max-width: 900px)": {
      marginBottom: 30,
    },
  },
  Tip: {
    background: `url('/assets/value.svg') no-repeat`,
    width: 50,
    height: 50,
    "& svg": {
      width: 60,
      height: 50,
    },
  },
});
const RangeSlider: React.FC<SliderProps> = (props: SliderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const numberFormatter = new Intl.NumberFormat();
  const formatter = (value) => {
    return <div>{numberFormatter.format(value)}</div>;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainTitle}>{t(`${props.title}`)}</div>
      <Slider
        marks={props.marks}
        step={props.step}
        tipFormatter={formatter}
        onChange={props.onChange}
        range
        max={props.maxValue}
        min={props.minValue}
        value={props.value}
        defaultValue={[props.minValue, props.maxValue]}
        reverse={false}
      />
    </div>
  );
};
export { RangeSlider };
