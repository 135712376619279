import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography, Button } from "shared";
import Mail from "../../../../public/assets/mail.svg";
import Phone from "../../../../public/assets/phone.svg";
import { useRouter } from "next/router";

const useStyles = createUseStyles({
  inquiryWrapper: {
    marginTop: 0,
    "@media(max-width: 768px)": {
      marginTop: 24,
    },
  },
  inquiryTitleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  inquiryTitle: {
    color: "#1B2E32",
    textAlign: "center",
    fontSize: 24,
    lineHeight: "36px",
    width: "100%",
    "@media(max-width: 768px)": {
      fontSize: 16,
      lineHeight: "24px",
      width: 280,
    },
  },
  inquiryContentWrapper: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 907px)": {
      marginTop: 22,
      padding: 0,
      flexDirection: "column",
      alignItems: "center",
    },
  },
  inquiryContent1: {
    width: 405,
    minHeight: 260,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FBF8F3",
    paddingTop: 50,
    paddingLeft: 56,
    paddingBottom: 68,
    paddingRight: 35,
    "@media(max-width: 768px)": {
      width: 318,
      height: "fit-content",
      padding: "50px 39px",
    },
  },
  inquiryContent2: {
    width: 408,
    minHeight: 260,
    background: "#FBF8F3",
    paddingTop: 50,
    paddingLeft: 66,
    paddingBottom: 43,
    paddingRight: 66,
    "@media(max-width: 768px)": {
      width: 318,
      height: "fit-content",
      padding: "44px 61px",
      margin: 0,
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    marginTop: 29,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 768px)": { marginTop: 22 },
  },
  mailButton: {
    background: "#009688",
    width: 314,
    color: "#fff",
    "&:hover": {
      background: "#009688",
      color: "#fff",
    },
    "@media(max-width: 768px)": {
      width: 240,
    },
    "& >span": {
      fontSize: 18,
      lineHeight: "27px",
      "@media(max-width: 768px)": {
        fontSize: 12,
        lineHeight: "18px",
      },
    },
  },
  inquiryText: {
    marginLeft: 0,
    fontSize: 24,
    lineHeight: "36px",
    "@media(max-width: 768px)": {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  image1: {
    width: 24,
    height: 24,
  },
  image2: {
    width: 28,
    height: 24,
  },
  phoneNumber: {
    marginTop: 0,
    textAlign: "center",
    fontSize: 36,
    lineHeight: "52px",
    "@media(max-width: 768px)": {
      marginTop: 8,
      fontSize: 22,
      lineHeight: "33px",
    },
  },
  phoneSubtitle: {
    color: "#82999B",
    textAlign: "center",
    fontSize: 14,
    lineHeight: "21px",
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  marginInquiry: {
    marginTop: 0,
    marginLeft: 82,
    "@media(max-width: 907px)": {
      marginTop: 48,
      marginLeft: 0,
    },
  },
});

const Inquiry = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const contactHandler = () => {
    router.push("/contact").then(() => window.scrollTo(0, 0));
  };
  return (
    <div className={classes.inquiryWrapper}>
      <div className={classes.inquiryTitleWrapper}>
        <Typography type="title3" className={classes.inquiryTitle}>
          {t("Contact US")}
        </Typography>
      </div>
      <div className={classes.inquiryTitleWrapper}>
        <Typography type="title3" className={classes.inquiryTitle}>
          {t("We are waiting for your opinions and requests on our service.")}
        </Typography>
      </div>
      <div className={classes.inquiryContentWrapper}>
        <div className={classes.inquiryContent1}>
          <div className={classes.imageWrapper}>
            <img src={Mail} className={classes.image1} />
            <Typography className={classes.inquiryText}>
              {t("Contact us via E-mail")}
            </Typography>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={contactHandler}
              type="primary"
              size="large"
              className={classes.mailButton}
            >
              {t("Contact Form")}
            </Button>
          </div>
        </div>

        <div className={`${classes.marginInquiry} ${classes.inquiryContent2}`}>
          <div className={classes.imageWrapper}>
            <img src={Phone} className={classes.image2} />
            <Typography className={classes.inquiryText}>
              {t("Call Us")}
            </Typography>
          </div>
          <Typography className={classes.phoneNumber}>080-3578-9185</Typography>
          <Typography className={classes.phoneNumber}>049-293-4004</Typography>
          <Typography className={classes.phoneSubtitle}>
            {t("Business hour: 10:00-18:00 Sunday & Holidays closed")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { Inquiry };
